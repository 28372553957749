export function cleanNumber(number) {
  return number.replace("(","").replace(")", "").replace("-","").replace(" ", "").replace("_","")
}

export function cleanAuthCode(authCode) {
  return authCode.replace(" ", "").replace(" ", "").replace(" ", "").replace(" ", "");
}

export function addPhoneDashes(f) {
  if(!f) return "";
  
  const f_val = f.replace(/\D[^\.]/g, "");
  return f_val.slice(0,3)+"-"+f_val.slice(3,6)+"-"+f_val.slice(6);
}

export function validEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function capitalize(str, lower = false) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

export function truncate(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export function textareaLines(string) {
  return string.split(/\n/)
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function numOfLines(textArea, lineHeight) {
  var h0 = textArea.style.height;
  ta.style.height = 'auto';
  var h1 = textArea.scrollHeight;
  textArea.style.height = h0;
  return Math.ceil(h1 / lineHeight);
}

export function randString() {
  return [...Array(30)].map(() => Math.random().toString(36)[2]).join('')
}