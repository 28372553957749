import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { RiListSettingsLine } from "react-icons/ri"
import CampaignModal from './CampaignModal'

export default function PromoteBlock({job, pullData}) {
    if (job === null || job === undefined) return(<></>)

    const [showModal, setShowModal] = useState(false)
    const [campaign, setCampaign] = useState(job.campaign)
    
    const updateShowModal = (show) => {
        setShowModal(show)
        pullData()
    }
    
    useEffect(() => { setCampaign(job.campaign)}, [job])

    const renderBtn = () => {
        if (campaign?.active) {
            return(<>
                <Button
                    className={'btn btn-secondary btn-block'}
                    onClick={() => { setShowModal(true) }}
                ><RiListSettingsLine/> Manage Budget</Button>
            </>)
        } else {
            return(<>
                <Button
                    className={'btn btn-primary btn-block'}
                    onClick={() => { setShowModal(true) }}
                ><img src={"/vecteezy_megaphone.png"} alt={"/"} width={'20px'}/>&nbsp;&nbsp;&nbsp;Promote Job&nbsp;&nbsp;&nbsp;</Button>
            </>)
        }
    }

    return(<>
        <CampaignModal
            showModal={showModal}
            onShowModal={updateShowModal}
            campaign={campaign}
            employerIdentifier={job.employer_identifier}
            jobIdentifier={job.identifier}
            jobTitle={(job.name + " (" + job.location + ")")}
        />
        {renderBtn()}
    </>)
}
