import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { FaBolt } from "react-icons/fa"

import JobSearchBar from "./JobSearchBar"
import JobSearchResults from "./JobSearchResults"
import ReactPaginate from 'react-paginate';
import InlineLogo from "../shared/InlineLogo"
import СampaignWinners from './СampaignWinners'

import styles from './JobSearchView.module.css'


  export default function JobSearchView({ winners, organization, filterOptions, center, jobs, recommendedJobs, jobTotal}) {
  const PER_PAGE = 25;
  const router = useRouter();
  const [initialPage, setInitialPage] = useState(router.query.page)

  useEffect(() => {
    if(jobTotal > 0 && jobs.length === 0) {
      router.query.page = 1;
      router.push(router);
    }
  }, [])
  useEffect(() => setInitialPage(router.query.page), [router.query.page]);

  const buildHeader = () => {
    return(
      <div className={styles['header']}>
        <h1 className={styles["header-title"]}>
          <InlineLogo/> {organization ? organization.name : "Construction Jobs"}
        </h1>
        <p className={styles["header-subtitle"]}>
          { organization ? "Apply to jobs with IEC Atlanta/GA Members"
            : "Apply to the top paying commercial, residential, and industrial construction jobs in the country."}
        </p>
      </div>
    )
  }

  const handlePaginate = (page) => {
    let newPage = parseInt(page.selected) + 1;

    router.query.page = newPage;
    router.push(router, undefined, { shallow: true });
    scrollTo(0,0)
  }

  if (jobTotal == 0) {
    return(
      <div className={'container'}>
        {buildHeader()}
        <div style={{marginTop: 50}}>
          <JobSearchBar filters={filterOptions} />
          <h2>No jobs match your search. Try again.</h2>
        </div>
      </div>
    );
  } else {
    return(
      <div className="container">
        {buildHeader()}
        <div style={{marginTop: 50}}>
          <JobSearchBar filters={filterOptions} />
          <СampaignWinners winners={winners} />
          <JobSearchResults center={center} jobs={jobs} recommendedJobs={recommendedJobs} jobTotal={jobTotal}/>
          {jobTotal > 0 ? <ReactPaginate style={{marginTop: "10px"}} previousLabel={"<"} nextLabel={">"} pageCount={jobTotal / PER_PAGE} pageRangeDisplayed={2} marginPagesDisplayed={0} initialPage={initialPage ? Math.max(parseInt(initialPage) - 1, 0) : 0} forcePage={initialPage ? Math.max(parseInt(initialPage) - 1, 0) : 0} onPageChange={handlePaginate} pageClassName={"page-item "  + styles["page-item"]} containerClassName={"pagination flex-wrap " + styles["paginator"]} pageLinkClassName={"page-link "  + styles["page-link"]} nextClassName={"page-link "  + styles["page-link"]} previousClassName={"page-link "  + styles["page-link"]} activeClassName={"active " + styles["active"]} breakLinkClassName={"page-link "  + styles["page-link"]} breakClassName={"page-item "  + styles["page-item"]}/> : ""}
        </div>
      </div>
    );
  };
};
