import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import style from './Map.module.css'
import {useEffect} from "react";

export default function Map({jobs, center, mobile}) {

    let map;

    const list_jobs = (jobs) => {
        let string = '';

        jobs.forEach(job => {
            string += job.employer + " - " + job.name + '\n'
        })

        return string
    }

    const prepare_jobs = (jobs) => {
        let array = [];

        jobs.forEach(job => {

            let list = [];

            array.forEach(h => { list.push(h.location.includes(job.location))})

            if (list.includes(true)) {
                let i = list.indexOf(true)
                array[i].location_jobs.push(job)
            } else {
                array.push({
                    'location': job.location,
                    'longitude': job.longitude,
                    'latitude': job.latitude,
                    'location_jobs': [job]
                })
            }
        });

        return array;
    }

    useEffect(  () => {
        mapboxgl.accessToken = 'pk.eyJ1IjoibWVldGxhZGRlciIsImEiOiJja3dsNTR2ZmExeXlqMnVwYTRqazZzbjZ5In0.k7lXAMyuoOAwK1gXETStRw';

        map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/meetladder/cl67hkoub001015ruosiobgus',
            center: [center.longitude, center.latitude ],
            zoom: 2.3,
        });

        map.on('style.load', () => {
            map.setFog({});
        });

        prepare_jobs(jobs).forEach(job => {
            let marker = new mapboxgl.Marker({
                type: 'Point',
                color: "#de6d43",
                draggable: false
            }).setLngLat([
                Number(job.longitude),
                Number(job.latitude)
            ]).setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(
                '<h6>' + job.location + '</h6>' +
                '<h6>' + job.location_jobs.length + ' Jobs</h6>' +
                "<a role='button' href='/jobs?page=1&location=" + job.location.replaceAll(',', '').replaceAll(' ', '-').toLowerCase()
                + "' class='btn btn-primary'> View Jobs</a>"
            )).addTo(map);
        });
    }, [jobs])


    if (mobile) {
        return <>
            <div id={'map'} className={style['mapbox-mobile']}/>
        </>
    } else {
        return <>
            <div id={'map'} className={style['mapbox']}/>
        </>
    }
}
