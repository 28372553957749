import JobCard from "./JobCard";
import {useEffect} from "react";
import {JobAPI} from "../../lib/ladder";

export default function СampaignWinners({winners}) {
    const jobElements = winners.map(job => <JobCard winners={true} job={job} key={"rec-" + job.identifier}/>)

    return  <>
        {jobElements}
    </>
}