import { useRouter } from 'next/router';

import JobCard from './JobCard';
import styles from './JobSearchResults.module.css'
import Map from './Map'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import {useState} from "react";

export default function JobSearchResults({recommendedJobs, center, jobs, jobTotal}) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const router = useRouter();
  const PER_PAGE = 25;

  const buildRecommendedJobs = () => {
    if(!recommendedJobs || recommendedJobs.length === 0) return;
    const jobElements = recommendedJobs.map(job => <JobCard winners={false} job={job} key={"rec-" + job.identifier}/>)

    return(
      <div className={styles['recommended-container']}>
        <h2 className={styles["recommended-title"]} >Recommended Jobs</h2>
        <div className={styles['recommended-jobs']}>
          {jobElements}
        </div>
      </div>
    )
  }

  const buildEmptyJobs = () => {
    return(
      <h2 className="text-center"><strong>No Jobs Found &nbsp; :(</strong></h2>
    )
  }
  
  const buildSearchJobs = () => {
    if(!jobs) return;

    const jobElements = jobs.map(job => <JobCard winners={false} job={job} key={job.identifier}/>)
    const jobPageCount = jobs.length ? `${jobTotal} Jobs Found - Page ${router.query.page || 1} of ${Math.ceil(jobTotal / PER_PAGE)}` : "";

    return(
      <div className={styles['all-container']}>
        <h2 className={styles["all-title"]}>{jobPageCount}</h2>
        {jobElements && jobElements.length > 0 ? jobElements : buildEmptyJobs()}
      </div>
    )
  }

    if (typeof window !== 'undefined') {
        if (window.screen.width <= 768) {
            // for small screen modal
            return<>
                <Row>
                    <Col sm={12} md={12} lg={6} lx={6} lxx={6}>
                        <button className={"btn btn-lg btn-primary btn-block " + styles['see-on-the-map']} onClick={handleShow}>Map view</button>
                    </Col>
                    <Col sm={12} md={12} lg={6} lx={6} lxx={6}>
                        {buildRecommendedJobs()}
                        {buildSearchJobs()}
                    </Col>
                </Row>
                <Modal show={show} onHide={handleClose}>
                    <div className={styles['content-mapbox']}>
                        <Map jobs={jobs} center={center} mobile={true}/>
                    </div>
                </Modal>
            </>
        } else {
            // for full screen window
            return<>
                <Row>
                    <Col sm={12} md={12} lg={6} lx={6} lxx={6}>
                        {buildRecommendedJobs()}
                        {buildSearchJobs()}
                    </Col>
                    <Col sm={12} md={12} lg={6} lx={6} lxx={6}>
                        <Map jobs={jobs} center={center} mobile={false}/>
                    </Col>
                </Row>
            </>
        }
    }

    return<></>
}
