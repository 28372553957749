import React, { useState, useEffect } from "react";

import { useRouter } from 'next/router'

import { JobAPI } from '../lib/ladder'

import Layout from '../components/Layout'
import SEO from "../components/SEO"
import JobsTable from "../components/JobsTable";
import ErrorView from "../components/ErrorView";
import JobSearchView from "../components/jobs/JobSearchView";
import ToastManager from "../lib/toastManager";
import UserManager from "../lib/userManager";

// In order to test subdomain functionality in local dev:
// 1. Manually change the subdomain in getServerSideProps to your organization's subdomain
// 2. Edit your /etc/hosts file to include something like "127.0.0.1       organization-2.ladderdev.com"
//     and set your browser to "organization-2.ladderdev.com:8080/jobs"

export async function getServerSideProps(context) {
  const authToken = UserManager.getAuthTokenServer(context);

  const subdomain = getSubdomain(context);
  const version = getVersion(context, subdomain);

  let data = ""
  if(version == "manage"){
    data = await JobAPI.all(authToken);
  } else {
    const filters = parseFilters(context.query, version);
    data = await JobAPI.search(authToken || null, context.query.page || 1, null, context.query.q, filters, version, subdomain);
  }

  return { props: { data: data, version: version, subdomain: subdomain } }
}

function getSubdomain(context){
  let subdomain = "";
  if (context.query.sd) {
    if (context.query.sd != "blank") { // override option to ignore url in staging env
      subdomain = context.query.sd;
    }
  }
  else {
    const domainParts = context.req.headers.host.split('.');
    if (domainParts.length == 3 && domainParts[0] != "www"){
      subdomain = domainParts[0];
    }
  }
  
  return subdomain;
}

function getVersion(context, subdomain){
  let version = "";
  const user = UserManager.buildUser("server", context);
  if(user.type === "employer" || user.type === "admin") {
    version = "manage";
  } else if(user.type === "worker") {
    version = "worker";
  } else if(subdomain != "") {
    version = "organization";
  } else {
    version = "atlanta";
  }
  return version;
}

export default function Jobs({data, version, subdomain}) {
  if(!data || !data.success) return(<ErrorView statusCode={data.statusCode}/>)
  const [jobsData, setJobsData] = useState(data);
  const firstRender = React.useRef(true);
  const router = useRouter();

  useEffect(() => {
    if(version === "manage") return;
    pullJobs();
  }, [JSON.stringify(router.query)])

  const pullJobs = async () => {
    const filters = parseFilters(router.query, version);
    const authToken = UserManager.getAuthToken();
    const newData = await JobAPI.search(authToken, router.query.page || 1, null, router.query.q, filters, version, subdomain);
    
    if(data.success) {
      setJobsData(newData);
    } else {
      ToastManager.errorToast("Unable to search jobs. Please reach out to support@meetladder.com.")
    }
  }

  if(version === "organization" && jobsData.organization) {
    const organization = {
      name: jobsData.organization.name,
      subdomain: jobsData.organization.subdomain,
      homePageUrl: jobsData.organization.home_page_url,
      logo: jobsData.organization.logo?.path
    }
    return (
      <div>
        <SEO  title={version === "organization" && jobsData.organization ? `${jobsData.organization.name} Jobs` : "Electrician Jobs in Atlanta, GA"} 
              description={"Ladder is your one-stop shop for commercial, residential, and industrial electrical jobs in the Atlanta metro area."} 
              path="/jobs" 
              publicVersion={true} />

        <Layout height={370} 
                employerLogo={subdomain === "iec-atlanta" ? "/assets/organizations/iec-atlanta-logo.png": null} 
                brandLink={"/jobs"} 
                domain={version === "organization" ? subdomain : null} >

          <JobSearchView 
              winners={ jobsData.winners } 
              filterOptions={ jobsData.filterOptions } 
              center={ jobsData.center } 
              jobs={ jobsData.jobs != undefined ? jobsData.jobs.results : [] }
              jobTotal={ jobsData.jobs != undefined ? jobsData.jobs.total : 0 }
              recommendedJobs={ jobsData.recommended_jobs != undefined ? jobsData.recommended_jobs.results : [] }
              organization={ organization } />
        </Layout>
      </div>
    )
  } else if(version === "atlanta") {
    return (
      <div>
        <SEO  title={"#1 job board for the people building America"}
              description={"Ladder is your one-stop shop for commercial, residential, and industrial electrical jobs in the Atlanta metro area."} 
              path="/jobs" 
              publicVersion={true} />
        <Layout 
              height={370}
              brandLink={"/jobs"}>

          <JobSearchView 
              winners={jobsData.winners}
              filterOptions={jobsData.filterOptions}
              center={jobsData.center}
              jobs={ jobsData.jobs != undefined ?  jobsData.jobs.results : [] }
              jobTotal={ jobsData.jobs != undefined ? jobsData.jobs.total : 0 }
              recommendedJobs={ jobsData.recommended_jobs != undefined ? jobsData.recommended_jobs.results : [] } />
        </Layout>
      </div>
    )
  } else if(version === "manage") {
    return (
      <div>
        <SEO title="Manage Jobs" path="/jobs"/>
        <Layout>
          <JobsTable data={data}/>
        </Layout>
      </div>
    )
  } else if(version === 'worker') {
    return (
      <div>
        <SEO  title={"#1 job board for the people building America"}
              description={"Ladder is your one-stop shop for commercial, residential, and industrial electrical jobs in the United States."} 
              path="/jobs" 
              publicVersion={true} />
        <Layout 
              height={370}
              brandLink={"/jobs"}>

          <JobSearchView 
              winners={jobsData.winners}
              filterOptions={jobsData.filterOptions}
              center={jobsData.center}
              jobs={ jobsData.jobs != undefined ?  jobsData.jobs.results : [] }
              jobTotal={ jobsData.jobs != undefined ? jobsData.jobs.total : 0 }
              recommendedJobs={ jobsData.recommended_jobs != undefined ? jobsData.recommended_jobs.results : [] } />
        </Layout>
      </div>
    )
  } else {
    ToastManager.errorToast("Unable to search jobs. Please reach out to support@meetladder.com.");
    return (
      <div>
        <Layout height={300}>
        </Layout>
      </div>
    )
  }
}

const parseFilters = (queryStr, version) => {
  if(!queryStr) return [];

  const filters =  [
    { id: "title_id",                 values: queryStr.title ? queryStr.title.split(",") : null, type: "in" },
    { id: "industry_id",              values: queryStr.industry ? queryStr.industry.split(",") : null, type: "in" },
    { id: "work_type_id",             values: queryStr.work_type ? queryStr.work_type.split(",") : null, type: "in" },
    { id: "project_type_ids",          values: queryStr.project_types ? queryStr.project_types.split(",") : null, type: "in" },
    { id: "min_years_of_experience",  values: queryStr.min_years_of_experience ? queryStr.min_years_of_experience.split(",") : null, type: "less_than_or_equal" },
    { id: "location_parameterized",   values: queryStr.location ? queryStr.location.split(",") : null, type: "in" },
  ]
  
  return filters.filter(item => item.values !== null && item.values !== undefined)
}