import React, { useState, useEffect, useMemo } from "react";

import Link from 'next/link'
import { useRouter } from 'next/router'

import { useTable, useFilters, useSortBy, usePagination , useGlobalFilter } from "react-table";
import { Dropdown } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown, FaCircle } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";

import Moment from 'react-moment';
import Select from 'react-select'

import { selectConfig } from '../configs/selectConfig'

import { truncate } from '../lib/inputUtils'
import { JobAPI } from '../lib/ladder'
import ToastManager from "../lib/toastManager"
import UserManager from "../lib/userManager"
import PromoteBlock from './PromoteBlock'

export default function JobsTable({data}) {
  const [statusSelect, setStatusSelect] = useState("")
  const [searchVal, setSearchVal] = useState("")
  const router = useRouter();

  const filterOptions = [
    { value: "", label: "All Jobs", default: true },
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
    { value: 'Draft', label: 'Draft' }
  ]

  return (
    <div>
      <style jsx> {`
        .box {
          width: 100%;
          background: #fff;
          box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          z-index: 1;
          border-top: 10px solid #ff7f50;
          padding: 0;
        }

        th {
          padding-left: 50px;
          padding-right: 50px;
          background: #F1F1F1;
          color: #757575;
          // font-weight: 500;
          text-transform: uppercase;
        }

        .table th {

        }

        .table-title {
          font-weight: bold;
          font-size: 3rem;
          color: #fff;
          margin-bottom: 50px;
        }

        .select-container, .search-container {
          margin-top: 15px;
        }
      `}
      </style>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1 className="table-title">Jobs</h1>
            </div>
            <div className="col-md-2">
              <div className="select-container">
                <Select instanceId={"select-item"} options={filterOptions} styles={selectConfig} defaultValue={filterOptions[0]} onChange={(option) => setStatusSelect(option.value)}/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="search-container">
                <input type="text" className="form-control" placeholder="Search jobs" onChange={(e) => setSearchVal(e.target.value)} style={{marginBottom: 20}}></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="box">
                <TableManager status={statusSelect} searchVal={searchVal} jobData={data.jobs}/>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}


function TableManager(props) {
  const data = useMemo(() => props.jobData, [props.jobData])

  const columns = useMemo(
     () => [
       {
         Header: 'Name',
         accessor: 'name', // accessor is the "key" in the data
       },
       {
         Header: 'Candidates',
         accessor: 'candidates',
         sortType: (rowA, rowB, columnId, desc) => {
          if(rowA.values.candidates.total > rowB.values.candidates.total) {
            return 1;
          } else if(rowA.values.candidates.total < rowB.values.candidates.total) {
            return -1;
          } else {
            return 0;
          }
         }
       },
       {
         Header: 'Status',
         accessor: 'status',
         filter: 'equals'
       },
       {
         Header: 'Location',
         accessor: 'location',
       },
       {
         Header: 'Created',
         accessor: 'created_at'
       },
       {
         Header: 'Manage',
         accessor: 'identifier',
         disableSortBy: true
       },
       {
         Header: 'Promote',
         accessor: 'promote',
         disableSortBy: true
       },
       {
         Header: "Slug",
         accessor: 'slug',
         disableSortBy: true
       }
     ],
     []
   )

  return(
    <Table columns={columns} tableData={data} status={props.status} searchVal={props.searchVal}/>
  )
}

const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <span className="btn btn-primary"><FaSortDown/></span>
      </a>
))

function Table({columns, tableData, status, searchVal}) {
  const [data, setData] = useState(tableData)
  const skipPageResetRef = React.useRef()
  const authToken = UserManager.getAuthToken();

  const updateData = (newData) => {
    skipPageResetRef.current = true
    setData(newData)
  }

  useEffect(() => {
    skipPageResetRef.current = false
  })

  const tableInstance = useTable(
      { 
        columns, 
        data,
        initialState: {
          sortBy: [
            { id: "created_at", desc: true }
          ],
          hiddenColumns: ["slug"]
        },
        autoResetPage: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        autoResetGroupBy: !skipPageResetRef.current,
        autoResetSelectedRows: !skipPageResetRef.current,
        autoResetSortBy: !skipPageResetRef.current,
        autoResetFilters: !skipPageResetRef.current,
        autoResetGlobalFilter: !skipPageResetRef.current,
        autoResetRowState: !skipPageResetRef.current,
      }, 
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance

  useMemo( 
    () => { 
      if(status != "") {
        tableInstance.setFilter("status", status) 
      } else {
        tableInstance.setFilter("status", null) 
      }
    },
    [status]
  );

  useMemo( 
    () => { 
      if(searchVal != "") {
        tableInstance.setGlobalFilter(searchVal) 
      } else {
        tableInstance.setGlobalFilter(null) 
      }
    },
    [searchVal]
  );

  const deleteJob = async (identifier) => {
    const res = await JobAPI.delete(authToken, identifier)

    if(res.success) {
      const newJobData = await JobAPI.all(UserManager.getAuthToken());
      updateData(newJobData.jobs);
    } else {
      ToastManager.errorToast(res.errors.join(", "))
    }    
  }

  const updateJobStatus = async (cell) => {
    const identifier = cell.value;
    const newJobStatus = (cell.row.values["status"] === "Inactive" || cell.row.values["status"] === "Draft") ?  "Active" : "Inactive"

    const res = await JobAPI.update(authToken, identifier,  { status: newJobStatus })

    if(res.success) {
      const newJobData = await JobAPI.all(UserManager.getAuthToken());
      updateData(newJobData.jobs);
    } else {
      ToastManager.errorToast(res.errors.join(", "))
    }
  }

  const pullData = async () => {
    const newJobData = await JobAPI.all(UserManager.getAuthToken());
    updateData(newJobData.jobs);
  }

  const CellLink = ({cell}) => {
    let statusText = ""

    if(cell.row.values["status"] === "Active") {
      statusText = "Deactivate Job";
    } else {
      statusText = "Activate Job";
    }

    return(
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" className="basic" as={CustomDropdownToggle}>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Link href={"/jobs/" + cell.value + "/manage"} passHref>
            <Dropdown.Item>Manage Candidates</Dropdown.Item>
          </Link>
          <Link href={"/jobs/" + cell.value +"/edit"} passHref>
            <Dropdown.Item>Edit Job</Dropdown.Item>
          </Link>
          <Link href={"/jobs/" + cell.row.values["slug"]} passHref>
            <Dropdown.Item>View Job</Dropdown.Item>
          </Link>
          <Dropdown.Item onClick={() => updateJobStatus(cell)}>{statusText}</Dropdown.Item>
          <Dropdown.Item onClick={() => deleteJob(cell.value)}>Delete Job</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }


  const renderCell = (cell) => {
    if(cell.column.id === "identifier") {
      return(
        <td {...cell.getCellProps()} className="table-cell" style={{paddingRight: "50px"}}>
          <style jsx> {`
            .table-cell {
              padding-top: 20px;
              padding-bottom: 20px;
              padding-left: 20px;
            }
          `}</style>
          <CellLink cell={cell}/>
        </td>
      )
    } else if(cell.column.id === 'promote'){
        return(
          <td {...cell.getCellProps()} className="table-cell">
          <style jsx> {`
            .table-cell {
              padding-top: 20px;
              padding-bottom: 20px;
              padding-right: 20px;
            }
          `}</style>
          <PromoteBlock job={cell.row.original} pullData={pullData}/>
        </td>
        )
    } else if(cell.column.id === "name") {
      return (
        <td {...cell.getCellProps()} className="table-cell" style={{paddingLeft: "50px"}}>
          <style jsx> {`
            .table-cell {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          `}</style>
          {// Render the cell contents
            <Link href={"/jobs/" + cell.row.values["identifier"] + "/manage"}><a className={"clean-link"}>{truncate(cell.value, 40)}</a></Link>
          }
        </td>
      )
    } else if(cell.column.id === "status") {
      return(
        <td {...cell.getCellProps()} className="table-cell">
          <style jsx> {`
            .table-cell {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          `}</style>
          {statusCell(cell)}
        </td>
      )
    } else if(cell.column.id === "candidates") {
      return(
        <td {...cell.getCellProps()} className="table-cell">
          <style jsx> {`
            .table-cell {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          `}</style>
          {candidatesCell(cell)}
        </td>
      )
    } else if(cell.column.id === "created_at") {
      return(
        <td {...cell.getCellProps()} className="table-cell">
          <style jsx> {`
            .table-cell {
              padding-top: 20px;
              padding-bottom: 20px;
            }
          `}</style>
          <Moment date={cell.value} format={"MMMM Do, YYYY"}/>
        </td>
      )
    } else {
      return (
        <td {...cell.getCellProps()} className="table-cell">
          <style jsx> {`
            .table-cell {
              padding-top: 20px;
              padding-bottom: 20px;
            }

            .table-cell td:first-child {
              padding-left: 50px !important;
            
          `}</style>
          {// Render the cell contents
          cell.render('Cell')}
        </td>
      )
    }
  }

  const sortArrows = (column) => {
    if(column.canSort) {
      if(column.isSorted) {
        if(column.isSortedDesc) {
          return(<FaSortDown style={{marginTop: "-5px"}}/>)
        } else {
          return(<FaSortUp style={{marginTop: "-5px"}}/>)
        }
      } else {
        return(<FaSort style={{marginTop: "-5px"}}/>)
      }
    } 
  }

  const candidatesCell = (cell) => {
      const totalCandidates = cell.value.total;
      const newCandidates =  cell.value.unseen;
      const identifier = cell.row.values["identifier"];

      return(
      <Link href={"/jobs/" + identifier + "/manage"} passHref>
        <a className="clean-link">
          {totalCandidates}
          <strong style={{color: "#FF7F50"}}> ({newCandidates} new)</strong>
        </a>
      </Link>
    )
  }

  const statusCell = (cell) => {
    if(cell.value === "Active") {
      return(
        <span><FaCircle style={{marginRight: "7px", fontSize: "13px", marginTop: "-2px", color: "#6FCF97"}}/>{cell.value}</span>
      )
    } else if(cell.value === "Inactive") {
      return(
        <span><FaCircle style={{marginRight: "5px", color: "#DE440C", fontSize: "13px", marginTop: "-2px"}}/>{cell.value}</span>
      )
    } else if(cell.value === "Draft") {
      return(
        <span><FaCircle style={{marginRight: "5px", color: "#ccc", fontSize: "13px", marginTop: "-2px"}}/>{cell.value}</span>
      )
    }
  }


  const renderTh = (column) => {
    if(column.id === "name") {
      return(
        <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{paddingLeft: "50px"}}>
          <style jsx> {`
          th {
            background: #F1F1F1;
            color: #757575;
            text-transform: uppercase;
          }
        `}</style>
          {// Render the header
          column.render('Header')}
          {
            <span style={{marginLeft: "5px"}}>
              {sortArrows(column)}
            </span>
          }
        </th>
      )

    } else if(column.id === "identifier") {
      return(
        <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{paddingRight: "50px"}}>
          <style jsx> {`
          th {
            background: #F1F1F1;
            color: #757575;
            text-transform: uppercase;
          }
        `}</style>
          {// Render the header
          column.render('Header')}
          {
            <span style={{marginLeft: "5px"}}>
              {sortArrows(column)}
            </span>
          }
        </th>
      )

    } else {
      return(
        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
          <style jsx> {`
          th {
            background: #F1F1F1;
            color: #757575;
            text-transform: uppercase;
          }
        `}</style>
          {// Render the header
          column.render('Header')}
          {
            <span style={{marginLeft: "5px"}}>
              {sortArrows(column)}
            </span>
          }
        </th>
      )
    }    
  }

  const buildPagination = () => {
    const start = (pageIndex * pageSize) + 1;
    const end = Math.min(start + pageSize - 1, rows.length)

    return(
      <div className="pagination" style={{padding: "20px 50px", width: "100%", borderTop: "1px solid #dee2e6"}}>
        <style jsx> {`
          .footer-right {
            /* height: 20px; */
            margin-top: -8px !important;
            /* width: 100%; */
            float: right !important;
            display: inline-block;
          }
          @media (max-width: 768px) {
            .footer-right, .footer-left {
              width: 100%;
              display: block;
              text-align: center;
            }
          }
        `}
        </style>
        <div style={{width: "100%"}}>
          <div className="row align-items-center">
            <div className="col-md-6">
              <span className="footer-left">
                Showing {start + "-" + end} of {rows.length} jobs
              </span>
            </div>
            <div className="col-md-6">
              <div className="footer-right">
                <button className="btn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>{' '}
                <button className="btn" onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>{' '}
                <button className="btn" onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>{' '}
                <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTable = () => {
    if(data.length === 0) {
      return(
        <div style={{textAlign: "center", padding: 60}}>
          <h1 style={{fontSize: "2.3rem",marginBottom: 50}}>You haven't posted any jobs!</h1>
          <div style={{marginTop: 50, marginBottom: 50}}>
            <img src={"/empty-jobs-v3.svg"} width={"30%"}/>
          </div>
          <Link href="/jobs/new" passHref>
            <a className="btn btn-primary" style={{fontSize: "1.1rem", marginTop: 10, paddingLeft: 20, paddingRight: 20}}><IoIosAddCircle style={{"marginBottom": "2px", marginRight: 5}}/>Post your first job</a>
          </Link>
        </div>
      )
    } else if(tableInstance.flatRows.length === 0){
      return(
        <div style={{textAlign: "center", padding: 60}}>
          <h1 style={{fontSize: "2.3rem",marginBottom: 50}}>No jobs found!</h1>
          <div style={{marginTop: 50, marginBottom: 50}}>
            <img src={"/empty-jobs-v3.svg"} width={"30%"}/>
          </div>
          <Link href="/jobs/new" passHref>
            <a className="btn btn-primary" style={{fontSize: "1.1rem", marginTop: 10, paddingLeft: 20, paddingRight: 20}}><IoIosAddCircle style={{"marginBottom": "2px", marginRight: 5}}/>Post a new job</a>
          </Link>
        </div>
      )
    }

    return(
      <div>
        <style jsx> {`
          th {
            background: #F1F1F1;
            color: #757575;
            text-transform: uppercase;
          }

          .table-title {
            font-weight: bold;
            font-size: 3rem;
            color: #fff;
            margin-bottom: 50px;
          }

          .table-wrap-wrap {
            overflow-y: visible;
            min-height: 100%;
          }

          .table-wrap {
            max-width: 100%;
          }

          table {
            width: 100%;
            min-width: 1000px;
          }

          @media (max-width: 900px) {
            .table-wrap {
              overflow-x: scroll;
            }
          }
        `}
        </style>
        <div className="table-wrap-wrap">
          <div className="table-wrap">
            <table {...getTableProps()} className="table">
              <thead>
                {// Loop over the header rows
                headerGroups.map(headerGroup => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {// Loop over the headers in each row
                      headerGroup.headers.map(column => (
                        // Apply the header cell props
                        renderTh(column)
                      ))
                    }
                  </tr>
                ))}
              </thead>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                page.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row)
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {// Loop over the rows cells
                      row.cells.map(cell => {
                        // const rCell = useMemo(() => renderCell(cell), [cell]);
                        // Apply the cell props
                        // return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        return renderCell(cell);
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        {buildPagination()}
      </div>

    )
  }

  return(
    <div>
      {renderTable()}
    </div>
  )
}
